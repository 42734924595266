<template>
  <div>
    <div class="header-text">
      <h1>{{ $t("privacyPolicy.title") }}</h1>
    </div>
    <section class="container content">
      <div class="">
        <p>
          {{ $t("privacyPolicy.description") }}
        </p>
      </div>
    </section>
    <section class="container content">
      <h2>1. {{ $t("privacyPolicy.whatDoesGlovelerDo") }}</h2>
      <p>
        {{ $t("privacyPolicy.whatDoesGlovelerDoContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>2. {{ $t("privacyPolicy.informationWeCollect") }}</h2>
      <p>
        {{ $t("privacyPolicy.informationWeCollectContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>3. {{ $t("privacyPolicy.personalData") }}</h2>
      <p>
        {{ $t("privacyPolicy.personalDataContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>4. {{ $t("privacyPolicy.whyWeCollect") }}</h2>
      <p>
        {{ $t("privacyPolicy.whyWeCollectContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>5. {{ $t("privacyPolicy.howWeShare") }}</h2>
      <p>
        {{ $t("privacyPolicy.howWeShareContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>6. {{ $t("privacyPolicy.howWeSharePersonal") }}</h2>
      <p>
        {{ $t("privacyPolicy.howWeSharePersonalContent") }}
      </p>
    </section>
    <section class="container content">
      <h2>7. {{ $t("privacyPolicy.howWeShareWithTransport") }}</h2>
      <p>
        {{ $t("privacyPolicy.howWeShareWithTransportContent") }}
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  components: {},
};
</script>

<style scoped>
.header-text {
  padding: 30px 0 60px 0;
  color: #fff;
  background: #ff762b;
  color: white;
  text-align: center;
}

.header-text h1 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 45px;
}

.content {
  margin-top: 50px;
  margin-bottom: 50px;
}
.content h1 {
  font-size: 35px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #444444;
}
.content h2 {
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bolder;
  color: #444444;
}
.content p {
  color: #969696;
}
.mt-10 {
  margin-top: 90px;
}
ul {
  margin: 0;
  padding: 0;
  margin-left: 16px;
}
ul li {
  list-style: decimal;
  color: #ff762b;
  padding: 5px 10px;
}
@media only screen and (max-width: 500px) {
  .banner {
    height: 30vh;
  }

  .content {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .banner-text {
    width: 100%;
  }

  .content h1 {
    font-size: 28px;
  }

  .content h2 {
    font-size: 23px;
  }

  .header-text h1 {
    font-size: 30px;
  }

  .mt-10 {
    margin-top: 35px;
  }
}
</style>
